<template>
  <div class="height1">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="goBack" content="处方审核"> </el-page-header>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <el-scrollbar class="left-nav-wrap" style="height: 100%">
          <el-descriptions class="margin-top" :column="3" border>
            <el-descriptions-item label="患者姓名">{{ patientDetail.name }}</el-descriptions-item>
            <el-descriptions-item label="年龄">{{ patientDetail.age }}岁</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ patientDetail.phone }}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{ patientDetail.idNo }}</el-descriptions-item>
            <el-descriptions-item label="联系地址">{{ patientDetail.address }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top" :labelStyle="LS" :contentStyle="CS" :column="2">
            <el-descriptions-item label="主诉">{{ DiagnoseDetial.main }}</el-descriptions-item>
            <el-descriptions-item label="现病史">{{ DiagnoseDetial.current }}</el-descriptions-item>
            <el-descriptions-item label="既往史">{{ DiagnoseDetial.past }}</el-descriptions-item>
            <el-descriptions-item label="过敏史">{{ DiagnoseDetial.irritability }}</el-descriptions-item>
            <el-descriptions-item label="家族史">{{ DiagnoseDetial.family }}</el-descriptions-item>
            <el-descriptions-item label="疾病诊断" label-class-name="my-label"> {{ DiagnoseDetial.diseaseDiagnose }}</el-descriptions-item>
          </el-descriptions>
          <el-divider></el-divider>
          <el-form label-width="100px" :model="RXForm" ref="RXForm" :rules="rules" style="width: 80%">
            <el-row>
              <el-col :span="17">
                <el-form-item label="处方名称" prop="prescriptionName">
                  <el-input v-model="RXForm.prescriptionName" placeholder="请输入处方名称" disabled> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1">
                <p style="visibility: hidden">-</p>
              </el-col>
              <el-col :span="4">
                <el-button type="primary" @click="drawer = true">选择处方</el-button>
              </el-col>
            </el-row>
            <el-form-item label="">
              <el-table class="tab" :data="RXForm.compositions" border style="width: 100%">
                <el-table-column prop="compositionName" align="center" label="成分">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.compositionId" v-if="scope.row.edit" filterable remote size="small" reserve-keyword placeholder="请输入成分" ref="selectLabel" :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                      <el-option v-for="item in EditDrugCompositio" :key="item.compositionId" :label="item.compositionName" :value="item.compositionId"> </el-option>
                    </el-select>
                    <!--<el-input
                                                v-if="scope.row.edit"
                                                v-model="scope.row.compositionName"
                                                size="small"
                                                placeholder="成分"
                                        ></el-input>-->
                    <span v-else v-html="scope.row.compositionName"></span>
                  </template>
                </el-table-column>
                <el-table-column prop="dosage" align="center" label="用量">
                  <template slot-scope="scope">
                    <el-input v-if="scope.row.edit" v-model="scope.row.dosage" size="small" placeholder="用量"></el-input>
                    <span v-else>{{ scope.row.dosage }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" align="center" label="炮制方法">
                  <template slot-scope="scope">
                    <el-select v-if="scope.row.edit" v-model="scope.row.remark" placeholder="炮制方法">
                      <el-option v-for="item in remarkOption" :key="item.value" :label="item.value" :value="item.value"> </el-option>
                    </el-select>
                    <!-- <el-input v-if="scope.row.edit" v-model="scope.row.remark" size="small"
                                            placeholder="炮制方法"></el-input> -->
                    <span v-else>{{ scope.row.remark }}</span>
                  </template>
                </el-table-column>
                <el-table-column width="150" align="center">
                  <template slot-scope="scope">
                    <el-button @click="confirmData(scope.row)" v-if="scope.row.edit" type="success" size="mini" plain>
                      <i class="el-icon-check" aria-hidden="true"></i>
                    </el-button>
                    <template v-else>
                      <el-button type="warning" size="mini" plain @click="editData(scope.row)">
                        <i class="el-icon-edit" aria-hidden="true"></i>
                      </el-button>
                    </template>
                    <el-button type="danger" size="mini" plain @click="handleDelete(scope.row, scope.$index)">
                      <i class="el-icon-delete" aria-hidden="true"></i>
                    </el-button>
                  </template>
                  <template slot="header">
                    <!-- 通过js添加行数据的按钮 -->
                    <el-button size="mini" @click="handleAdd()" type="primary" plain round>添加成分 </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>

            <el-row>
              <el-col :span="12">
                <el-form-item label="剂量" prop="dosage">
                  <el-input v-model.number="RXForm.dosage" type="number" placeholder="单位（剂）"> </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用法用量" prop="number">
                  <el-input v-model.number="RXForm.number" type="number" placeholder="（次/日）"> </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="快递方式">
              <el-radio-group v-model="RXForm.isSelef" disabled>
                <el-radio :label="true">自取</el-radio>
                <el-radio :label="false">快递</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="其他备注">
              <el-input v-model="RXForm.remark" type="textarea" disabled></el-input>
            </el-form-item>
            <p style="height: 30px; line-height: 30px; background-color: #ececec; margin: 10px 0; padding-left: 15px" v-if="compatibilityRes.length > 0">以下配伍出自《中国药典》</p>
            <div class="tab-content">
              <div>
                <li v-for="(compare, index) in compatibilityRes" :key="index + 'i'">
                  <div class="peitab-tit" @click="showCourseInfoList($event)">
                    <div v-if="compare.compatibilityType == 7">
                      <h4 v-for="(tip, index1) in compare.tips" :key="index1 + 'j'">
                        <span>{{ compare.sourceDrugName }} : {{ tip.targetDrugName }} </span>
                      </h4>
                    </div>
                    <div v-else>
                      <h4>
                        <span>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} 存在以下配伍结果</span>
                      </h4>
                      <div class="show-more">
                        <span class="show-text">{{ showCourseInfo ? '收起' : '展开' }}</span>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                    </div>
                  </div>
                  <div v-if="compare.tips" class="peitab-mes">
                    <div v-for="(types, index1) in tipGroup(compare.tips)" :key="index1 + 'k'">
                      <div v-if="types.keys <= 3">
                        <p v-for="(val, index2) in types.value" :key="index2 + 'l'" v-html="namehtmlraw(val)"></p>
                      </div>
                      <div v-if="types.keys == 4">
                        <div v-for="(val, index2) in types.value.filter(i => i.title == '临床建议')" :key="index2 + 'n'">
                          <p v-html="namehtmlraw(val)"></p>
                        </div>
                      </div>
                      <div v-if="types.keys == 5">
                        <p v-for="(val, index2) in types.value" :key="index2 + 'o'" v-html="namehtmlraw(val)"></p>
                      </div>
                      <div v-if="types.keys == 7">
                        <p v-for="(val, index2) in types.value" :key="index2 + '6'">建议单独使用</p>
                      </div>
                      <div v-if="types.keys == 8">
                        <p v-for="(val, index2) in types.value.filter(i => i.title == '结果')" :key="index2 + 'p'" v-html="namehtmlraw(val)"></p>
                      </div>
                    </div>
                  </div>
                  <div v-else class="peitab-mes">
                    <p>{{ compare.sourceDrugName }} 与 {{ compare.targetDrugName }} ： 无配伍资料或配伍意见不一致。</p>
                  </div>
                </li>
              </div>
            </div>
            <el-form-item>
              <el-button @click="goBack">取消</el-button>
              <el-button type="primary" @click="saveRX('RXForm')">确定处方</el-button>
              <!--                            <el-button @click="saveRX('RXForm')">预览</el-button>-->
            </el-form-item>
          </el-form>
        </el-scrollbar>
      </div>
    </div>
    <el-drawer title="中药方剂库" size="550px" :visible.sync="drawer" :direction="direction">
      <div class="drawer_body">
        <div class="iq-search-bar">
          <el-form action="#" class="searchbox" @submit.native.prevent>
            <input type="text" class="text search-input" v-model="Keyword" placeholder="输入关键词搜索..." @keydown="SearchInputKeydown" />
            <a class="search-link" href="javascript:;" @click="Search"><i class="el-icon-search"></i></a>
          </el-form>
        </div>
        <div class="category_box">
          <el-button :class="categoryId == item.categoryId ? 'active' : ''" plain size="small" v-for="(item, index) in herbalNavList" @click="searchHerbal(item.categoryId)"> {{ item.categoryName }}</el-button>
        </div>
        <div class="herbalList">
          <el-table v-loading="loading" height="450" :data="herbalData" style="width: 100%" @row-click="rowClick">
            <el-table-column prop="herbalPrescriptionName" label="方剂名称"> </el-table-column>
            <el-table-column label="操作" width="150px">
              <template slot-scope="scope">
                <el-button size="mini" type="success" @click="loadRXFrom(scope.row)" plain>载入 </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="StaffTotal" layout="total, prev, pager, next, jumper"></el-pagination>
        </div>
        <div class="herbalDetail">
          <el-table v-loading="loadings" height="450" :data="herbalDetail.compositions" style="width: 100%" @row-click="rowClick">
            <el-table-column prop="compositionName" label="成分" width="180">
              <template slot-scope="scope">
                <div v-html="scope.row.compositionName"></div>
              </template>
            </el-table-column>
            <el-table-column prop="dosage" label="用量" width="180"> </el-table-column>
            <el-table-column prop="remark" label="炮制方法"> </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Patient } from '../../../components/HospitalDomain/Patient/Patient'
import { Herbal } from '../../../components/HospitalDomain/Patient/Herbal'
import { Promise } from '../../../utils/es6-promise.min'
import { tipGroup, namehtmlraw } from '../../../utils/Compatibility'

export default {
  name: 'AuditingPrescription',
  data() {
    var patient = new Patient(this.TokenClient, this.Services.Authorization)
    var herbal = new Herbal(this.TokenClient, this.Services.Drug)
    return {
      patientDomain: patient,
      herbalDomain: herbal,
      patientPrescriptionId: this.$route.query.patientPrescriptionId, //列表页id
      patientId: this.$route.query.patientId, //列表页id
      patientDiagnoseId: this.$route.query.patientDiagnoseId, //列表页id
      patientDetail: {},
      DiagnoseDetial: {},
      showCourseInfo: false,
      drawer: false,
      direction: 'rtl',
      Keyword: '',
      searchId: '0',
      categoryId: 0,
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      StaffTotal: 0,
      remarkOption: [
        {
          value: '炒法',
        },
        {
          value: '炙法',
        },
        {
          value: '煅法',
        },
        {
          value: '蒸法',
        },
        {
          value: '煮法',
        },
        {
          value: '燀法',
        },
        {
          value: '复制法',
        },
        {
          value: '发酵法',
        },
        {
          value: '发芽法',
        },
        {
          value: '制霜法',
        },
        {
          value: '烘焙法',
        },
        {
          value: '煨法',
        },
        {
          value: '提净法',
        },
        {
          value: '水飞法',
        },
        {
          value: '干馏法',
        },
        {
          value: '特殊制法',
        },
      ],
      RXForm: {
        patientId: 0,
        patientDiagnoseId: 0,
        prescriptionName: '',
        isSelef: true,
        dosage: null,
        number: null,
        prescriptionType: 2,
        compositions: [
          {
            edit: true,
            compositionName: '',
            remark: '',
            dosage: '',
            category: null,
            categoryName: null,
            compositionAlias: null,
            compositionId: null,
          },
        ],
      },
      LS: {
        'word-break': 'keep-all',
        width: '100px',
      },
      CS: {
        'max-width': '500px',
        'word-break': 'break-all',
      },
      rules: {
        prescriptionName: [{ required: true, message: '请输入处方名称', trigger: 'blur' }],
        dosage: [{ required: true, message: '请输入剂量', trigger: 'blur' }],
        number: [{ required: true, message: '请输入用法用量', trigger: 'blur' }],
      },
      herbalNavList: [],
      herbalData: [],
      loading: false,
      herbalDetail: {},
      loadings: false,
      EditDrugCompositio: [],
      preview: false,
      compatibilityRes: [],
    }
  },
  mounted() {
    if (this.patientId) {
      this.getDetail()
      this.getcur()
      this.getRX()
    }
    this.getHerbalCategorys()
  },
  methods: {
    //列表项----点击展开收起
    showCourseInfoList(e) {
      const target = e.currentTarget
      // 寻找父元素的兄弟元素进行显示与隐藏控制
      $(target).siblings().toggle()
      // 切换样式-图标
      $(target).find('i').toggleClass('el-icon-arrow-down').addClass('el-icon-arrow-up')
      if ($(target).siblings().is(':hidden')) {
        $(target).find('.show-text').html('展开')
      } else {
        $(target).find('.show-text').html('收起')
      }
    },
    tipGroup(list) {
      return tipGroup(list)
    },
    namehtmlraw(item) {
      return namehtmlraw(item)
    },
    goBack() {
      this.$router.go(-1)
    },
    getDetail() {
      var _this = this
      _this.patientDomain.GetDetail(
        _this.patientId,
        function (data) {
          _this.patientDetail = data.data
          _this.RXForm.patientId = parseInt(_this.patientId)
          _this.RXForm.patientDiagnoseId = parseInt(_this.patientDiagnoseId)
          console.log(_this.RXForm.patientDiagnoseId)
        },
        function (err) {}
      )
    },
    getcur() {
      var _this = this
      _this.patientDomain.DiagnoseDetial(
        _this.patientDiagnoseId,
        function (data) {
          _this.DiagnoseDetial = data.data
        },
        function (err) {}
      )
    },
    getRX() {
      var _this = this
      _this.herbalDomain.GetRXDetail(
        _this.patientPrescriptionId,
        function (data) {
          _this.RXForm = data.data
          _this.RXForm.compositions = []
          var array = []
          data.data.patientPrescriptionList.forEach((item, i) => {
            _this.RXForm.compositions.push({
              edit: true,
              compositionName: item.patientComposition.compositionName,
              remark: item.remark,
              dosage: item.dosage,
              category: null,
              categoryName: item.patientComposition.categoryName,
              compositionAlias: item.patientComposition.compositionAlias,
              compositionId: item.patientComposition.drugCompositionId,
            })
            if (item) {
              array.push({
                type: 4,
                key: item.key,
              })
            }
            // array.push(item.patientComposition.compositionName)
          })
          _this.EditDrugCompositio = _this.RXForm.compositions.map(function (item) {
            return {
              compositionId: item.compositionId,
              compositionName: item.compositionName,
            }
          })
          _this.toCompatibility(array).then(res => {})
          console.log('++++++++')
        },
        function (err) {
          _this.RXData = []
          console.log(_this.RXData)
        }
      )
    },
    toCompatibility(array) {
      var _this = this
      _this.compatibilityRes = []
      return new Promise(res => {
        // array.toString()
        // 伊贝母,浙贝母,制草乌,丁香,草乌,制草乌,瓜蒌子,白及
        _this.herbalDomain.compatibility(array,
          function (data) {
            _this.compatibilityRes = res
            res(data.data)
          },
          function (error) {
            res('')
          }
        )
      })
    },
    //规格增删改---------开始
    handleAdd() {
      let row = {
        edit: true,
        compositionName: '',
        remark: '',
        dosage: '',
        category: null,
        categoryName: null,
        compositionAlias: null,
        compositionId: null,
      }
      this.RXForm.compositions.push(row)
    },
    editData(row) {
      console.log(row)
      this.$set(row, 'edit', true)
    },
    confirmData(row) {
      console.log(row)
      row.edit = false
      this.$notify({
        title: 'Success',
        message: '编辑成功',
        type: 'success',
        duration: 2000,
      })
    },
    handleDelete(row, index) {
      this.$notify({
        title: 'Success',
        message: '删除成功',
        type: 'success',
        duration: 2000,
      })
      this.RXForm.compositions.splice(index, 1)
      this.RXForm.patientPrescriptionList.splice(index, 1)
    },
    //规格增删改---------结束
    //中药方剂库---------开始
    Search() {},
    SearchInputKeydown() {},
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.searchHerbal(this.categoryId)
    },
    getHerbalCategorys() {
      var _this = this
      _this.herbalDomain.Categorys(
        function (data) {
          _this.herbalNavList = data.data
        },
        function (error) {
          console.log('左侧分类请求失败:', error)
        }
      )
    },
    searchHerbal(item) {
      var _this = this
      _this.categoryId = item
      _this.loading = true
      _this.herbalDomain.Herbals(
        _this.searchId,
        _this.categoryId,
        _this.PageIndex,
        _this.Keyword,
        function (data) {
          _this.herbalData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.StaffTotal = data.data.dataTotal
          _this.loading = false
        },
        function (error) {
          console.log('左侧分类请求失败:', error)
        }
      )
    },
    rowClick(row) {
      var _this = this
      _this.loadings = true
      console.log(row.herbalPrescriptionId)
      _this.herbalDomain.Detail(
        row.herbalPrescriptionId,
        '0',
        _this.Keyword,
        function (data) {
          _this.herbalDetail = data.data
          _this.loadings = false
        },
        function (error) {
          console.log('左侧分类请求失败:', error)
        }
      )
    },
    loadRXFrom(row) {
      var _this = this
      _this.loadings = true
      _this.RXForm.compositions = []
      _this.herbalDomain.Detail(
        row.hashKey,
        function (data) {
          _this.herbalDetail = data.data
          _this.loadings = false
          _this.RXForm.prescriptionName = row.herbalPrescriptionName
          var array = []
          _this.herbalDetail.compositions.forEach((item, i) => {
            _this.RXForm.compositions.push({
              compositionName: item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/, '$1'),
              remark: item.remark,
              dosage: item.dosage,
              category: item.category,
              categoryName: item.categoryName,
              compositionAlias: item.compositionAlias,
              compositionId: item.compositionId,
            })
            array.push(item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/, '$1'))
            _this.EditDrugCompositio = _this.RXForm.compositions.map(function (item) {
              return {
                compositionId: item.compositionId,
                compositionName: item.compositionName,
              }
            })
          })
          _this.compatibilityRes = []
          _this.drawer = false
        },
        function (error) {
          console.log('左侧分类请求失败:', error)
        }
      )
    },
    SelectComposition(val) {
      console.log(val)
      var _this = this
      _this.herbalDomain.Compositions(
        val,
        '中成药',
        function (data) {
          _this.EditDrugCompositio = data.data.map(function (item) {
            var label = `${item.compositionName}`
            return {
              compositionName: label,
              compositionId: item.compositionId,
            }
          })
        },
        function (err) {
          console.log(err)
        }
      )
    },
    ConstituteChange(val, item) {
      this.EditDrugCompositio.map(res => {
        if (res.compositionId == val) {
          item.compositionName = res.compositionName
        }
      })
    },
    /*//中药方剂库---------结束
        //预览处方
        previewBtn(formName){
            console.log(this.RXForm)
            var _this= this;
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.preview = true
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },*/

    //保存处方
    async saveRX() {
      console.log(this.RXForm)
      var _this = this
      if (_this.RXForm.compositions.length > 1) {
        var array = []
        _this.RXForm.compositions.forEach(item => {
          if (item) {
            array.push(item.compositionName)
          }
        })
        _this.toCompatibility(array).then(res => {
          if (_this.compatibilityRes && _this.compatibilityRes.length > 0) {
            _this.compatibilityRes = res
            _this
              .$confirm('该处方配伍异常, 是否继续提交?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
              .then(() => {
                _this.saveData()
              })
              .catch(() => {})
          } else {
            _this.saveData()
          }
        })
      } else {
        _this.saveData()
      }
    },
    saveData() {
      var _this = this
      _this.herbalDomain.AuditPatientPrescription(
        _this.RXForm,
        function (data) {
          _this.$message({
            type: 'success',
            message: '处方审核成功!',
          })
          _this.$router.go(-1)
        },
        function () {}
      )
    },
  },
}
</script>

<style scoped>

.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}

.drawer_body {
  padding: 0 20px 20px;
}

.category_box {
  padding: 15px 0;
  border-bottom: 1px solid #eeeeee;
}

.category_box .el-button {
  margin: 5px;
}

.category_box .el-button.active {
  color: #fff;
  background-color: var(--baseColor);
  border-color: var(--baseColor);
}

.tab /deep/ .el-form-item__content {
  height: 55px;
}

.tab /deep/ .el-form-item__content .el-form-item__error {
  top: 40px;
}

.elSelect /deep/ .el-select-dropdown__item span {
  max-width: 170px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tab-content {
  margin-top: 30px;
}

.tab-content ul li {
}

.tab-content ul li:first-child {
}

.peitab-tit {
  position: relative;
  border-top: 1px solid #e5e5e5;
  padding: 10px 15px;
}

.peitab-tit h4 {
  display: inline-block;
  width: 830px;
  font-weight: normal;
  color: #333333;
  font-size: 16px;
}

.peitab-tit h4 span {
  color: #e94343;
}

.peitab-tit .show-more {
  display: inline-block;
  position: absolute;
  right: 0px;
  cursor: pointer;
  font-size: 14px;
  color: #666666;
}

.peitab-mes {
  display: none;
  width: 830px;
  padding: 5px 25px;
}

.peitab-mes p {
  line-height: 30px;
  color: #666666;
}

.peitab-mes .linkDetail {
  display: inline-block;
  color: #01c2ac;
  cursor: pointer;
}

.peitab-mes .linkDetail:hover {
  text-decoration: underline;
}

.el-descriptions {
  margin-bottom: 10px;
}
</style>
<style>
.my-label {
  color: red;
}
</style>
