let Herbal = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取中药方剂搜索列表数据
     * @param searchId
     * @param categoryId
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.Herbals = function (searchId, categoryId, pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/HerbalPrescription/Search';
        // if (searchId) {
        //     url += `/${searchId}`;
        // }
        if (categoryId) {
            url += `/${categoryId}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        } else {
            url += '/%20';
        }

        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取中药方剂分类
     * @param {any} success
     * @param {any} error
     */
    this.Categorys = function (success, error) {
        var url = ServiceBaseAddress + '/api/HerbalPrescription/Categories';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取中药方剂详情数据
     * @param id
     * @param searchId
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.Detail = function (hashKey, success, error) {
        var url = ServiceBaseAddress + '/api/HerbalPrescription/'+hashKey;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.getHerbalPrescriptionDetail = function (hashKey, success, error) {
        var url = ServiceBaseAddress + `/api/HerbalPrescription/${hashKey}`
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    };

    this.Compositions = function (name,category , success, error) {
        var url = ServiceBaseAddress + `/api/Drug/Manager/Compositions/${name}/${category}`;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.SavePrescription = function (item, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, item,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 配伍
     * @param id
     * @param searchId
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    //  this.compatibility = function (name, success, error) {
    //     var url = ServiceBaseAddress + '/api/Compatibility/PatientPrescription?compositionName='+name;
    //     TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, null,
    //         function (data) {
    //             if (success) {
    //                 var jsonObj = JSON.parse(data);
    //                 success(jsonObj);
    //             }
    //         }, error);
    // }
    this.compatibility = function (items, success, error) {
        // var url = ServiceBaseAddress + '/api/Compatibility';
        var url = ServiceBaseAddress + "/api/Compatibility/Query"
        var params = {
            items: items,
            types: [0],
        }
        TokenClient.Post(
            url,
            false,
            { "Content-Type": "application/json" },
            params,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    };
    /**
     * 获取患者某次复诊记录处方数据
     * @param patientId
     * @param patientDiagnoseId
     * @param auditStatus
     * @param success
     * @param error
     * @constructor
     */
    this.GetPatientPrescription = function (patientId, patientDiagnoseId, auditStatus, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription/GetPatientPrescription';
        if (patientId) {
            url += `/${patientId}`;
        }
        if (patientDiagnoseId) {
            url += `/${patientDiagnoseId}`;
        }
        if (auditStatus) {
            url += `/${auditStatus}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 查看患者处方详情
     * @param patientPrescriptionId
     * @param success
     * @param error
     * @constructor
     */
    this.GetRXDetail = function (patientPrescriptionId, success, error) {
        var url = ServiceBaseAddress + '/api/PatientPrescription/GetDetail/'+ patientPrescriptionId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    this.AuditPatientPrescription = function(item, success, error) {
        if (item && item.id) {
            var url = ServiceBaseAddress + '/api/PatientPrescription/AuditPatientPrescription';
            TokenClient.Post(url, true, {'Content-Type': 'application/json'}, item,
                function (data) {
                    if (success) {
                        var jsonObj = JSON.parse(data)
                        success(jsonObj)
                    }
                },
                error
            )
        }
    }
}
export { Herbal }
