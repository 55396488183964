import { GroupBy } from '../utils/extend'

//配伍js
//Compare对比栏操作

const ckOptions = [
	{
		name: '配伍',
		value: 0,
	},
	{
		name: '禁忌',
		value: 1,
	},
	{
		name: '饮食',
		value: 2,
	},
	{
		name: '老人',
		value: 3,
	},
	{
		name: '儿童',
		value: 4,
	},
	{
		name: '妊娠',
		value: 5,
	},
	{
		name: '哺乳',
		value: 6,
	},
]
//获取名字
export function getckOptionsNames() {
	var names = []
	ckOptions.forEach(function(item) {
		names.push(item.name)
	})
	return names
}
//name value 转换
export function nameConvertValue(stringArr) {
	var values = ''
	ckOptions.forEach(function(item) {
		if (stringArr.indexOf(item.name) != -1)
			values = values + item.value + '|'
	})
	return values
}

//name value 转换
export function valueConvertName(selectChecks) {
	var names = []
	ckOptions.forEach(function(item) {
		if (selectChecks.indexOf(item.value) != -1) names.push(item.name)
	})
	return names
}

//tip分组
export function tipGroup(list) {
	if (list) {
		const results = GroupBy(list, function(item) {
			return [item.categoryCode]
		})
		var dic = []
		results.forEach(function(item) {
			// if (item[0].categoryCode != 8) {
			//     var interaction = {
			//         keys: item[0].categoryCode,
			//         value: item
			//     }
			//     dic.push(interaction)
			// }
			var interaction = {
				keys: item[0].categoryCode,
				value: item,
			}
			dic.push(interaction)
		})
		return dic
	}
}

//名字HTML转换
export function namehtmlraw(item) {
	var result1 =
		item.drug1Name == null
			? item.item1Name == null
				? item.item1CategoryName == null || item.item1CategoryName == ''
					? item.item1Remark + '类中药'
					: item.item1CategoryName
				: item.item1Remark == null || item.item1Remark == ''
				? item.item1Name
				: `${item.item1Name}（${item.item1Remark}）`
			: item.drug1Name
	var result2 =
		item.drug2Name == null
			? item.item2Name == null || item.item2Name == ''
				? item.item2CategoryName
				: item.item2Name
			: item.drug2Name
	var result = ''
	switch (item.categoryCode) {
		case 0:
		case 1:
		case 2:
		case 4:
			result = `${result1} 与 ${result2} ：${item.content.replace(
				/\n/g,
				'<br>'
			)}`
			break
		case 5:
			result = `${result1} 与 ${result2} ：两药不宜合用`
			break
		case 8:
			result = `${result1} 与 ${result2} ：${item.content
				.replace('结果：', '')
				.replace(/\n/g, '<br>')}`
			break
	}
	return result
}

//饮食禁忌 分组
export function dietGroup(list) {
	const results = GroupBy(list, function(item) {
		return [item.target]
	})
	var dic = []
	results.forEach(function(item) {
		var interaction = {
			keys: item[0].target,
			value: item,
		}
		dic.push(interaction)
	})
	return dic
}

//内容HTML转换
export function contenthtmlRaw(item, isdiet = false) {
	var info = ''
	if (!isdiet) {
		if (item.target) {
			if (item.content.indexOf('\n') > -1) {
				info = `<p><b>${item.target}</b></p><p>${item.content.replace(
					/\n/g,
					'</p><p>'
				)}</p>`
			} else {
				info = `<p><b>${item.target}</b>：${item.content}</p>`
			}
		} else {
			info = `<p>${item.content.replace(/\n/g, '</p><p>')}</p>`
		}
	} //饮食禁忌
	else {
		info = `<p><b>${item.keys}</b></p>`
		item.value.forEach(function(i) {
			if (i.content.indexOf('\n') > -1) {
				info += `<p>${i.content.replace(/\n/g, '</p><p>')}</p><p></p>`
			} else {
				info += `<p>${i.content}</p>`
			}
		})
	}
	info += '<p>&nbsp;</p>'
	return info
}

//特殊人群列表
export function getcrowdList(instructions) {
	var result = []
	var laoren = instructions[3]
	var ertong = instructions[4]
	var renchen = instructions[5]
	var buru = instructions[6]
	if (laoren) {
		result.push({
			tag: '老人用药',
			value: laoren,
		})
	}
	if (ertong) {
		result.push({
			tag: '儿童用药',
			value: ertong,
		})
	}
	if (renchen) {
		result.push({
			tag: '妊娠用药',
			value: renchen,
		})
	}
	if (buru) {
		result.push({
			tag: '哺乳用药',
			value: buru,
		})
	}
	return result
}
